;(function($) {

	"use strict";


	$(document).ready(function(){

		// Добавление состояний is-focus, is-active
	    $('input, textarea').on('blur change keyup paste input', function() {
			var self = $(this);
			if (self.val() == '') {
				if(!self.is(':focus')){
					self.closest('.form-group').removeClass('is-active');
				}
			} else {
				self.closest('.form-group').addClass('is-active');
			}
	    });
	    $('input, textarea').on('focus active', function() {
	        $(this).closest('.form-group').addClass('is-focus is-active');
	    });
	    $('input, textarea').on('blur', function() {
	        $(this).closest('.form-group').removeClass('is-focus');
	    });

		// custom file input
		var FileCustomInput = {
			init: function(){
				var wrapperFile = $('.c-file');
				var fileInput = $('input[type="file"]');
				for (var i = 0; i < fileInput.length; i++) {
					fileInput[i].onchange = function() {
						var input = $(this).closest(wrapperFile).find('.c-file__path')[0];
						var text = [];
						var inputText = '';
						if($(this).attr('multiple')){
							for( var f = 0; f < this.files.length; f++){
								text.push( this.files[f].name );
							}
							inputText = text.join(', ');
						}
						else{
							inputText = this.files[0].name;
						}
						input.value = inputText;
					}
				}
			}
		}
		FileCustomInput.init();

	});

})(window.jQuery);
