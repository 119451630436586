import 'jquery';
import 'popper.js';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

import 'node-waves';
import 'swiper/js/swiper.js';
import 'jquery.maskedinput/src/jquery.maskedinput';
import 'tablesaw/dist/tablesaw.jquery';
import 'tablesaw/dist/tablesaw-init';
import 'sticky-kit/dist/sticky-kit';
import 'bootstrap-notify';
import 'jquery.scrollbar';
import 'magnific-popup';

import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/localization/messages_ru';
import './vendor/jquery-validation/src/additional/minlengthphone';

import './vendor/modernizr/modernizr-custom';
import './vendor/liFixar/jquery.liFixar';
import './vendor/fullscreen/fullscreen';
import './vendor/resizer/resizer';
import './vendor/sidebarmenu/sidebarmenu';
import './vendor/tableHeadFixer/tableHeadFixer';
import './vendor/jquery.slidereveal/jquery.slidereveal';
import './vendor/StickyTableHeaders/jquery.stickytableheaders';

import './vendor/form/form';
import './vendor/form/c-select';

import './vendor/svg/svg-sprite.js';
